<!-- 轨迹查询页面 -->
<template>
  <div class="track" style="overflow-x:hidden">
    <el-row style="margin-top: 100px;" :gutter="24">
      <el-col :md="{span:12,offset:6}" :sm="{span:12,offset:4}">
        <div class="gfs">
          <img
            src="@/assets/FleeTan.png"
            alt
            style="width:40%;vertical-align:middle;margin-bottom:30px"
          />
        </div>
      </el-col>
      <el-col
        :md="{span:12,offset:6}"
        :sm="{span:12,offset:4}"
        style="position:relative;top:0;padding-left:0"
      >
				<!-- @keyup.enter.native="orderGui" -->
			  <!-- zpy 2022-6-22 查询轨迹可批量查询 以换行符隔开订单号 -->
        <el-input
          style="width:86%;margin-left:20px;"
					type="text"
          v-model="formInline.input"
          placeholder="Input Order Number"
          @keyup.enter.native="orderGui"
        />
        <el-button
          style="width:18%;position:absolute;right:7%;height:48px;font-size:0.3rem;"
          type="primary"
          @click="orderGui"
        >Track</el-button>
      </el-col>
    </el-row>
		<!-- pod照片弹窗 -->
		<el-dialog
		  title="POD Photos"
		  :visible.sync="viewPodPhothos"
		  width="86%"
		  append-to-body
		  class="timeline"
		>
		  <div
		    class="prod-item"
		    v-for="(item,index) in dynamicPods"
		    :key="index"
		    style="width:300px;margin-right:10px"
		  >
		    <!-- <img :src="item.link" alt style="width:300px;" /> -->
		    <el-image :src="item.link" :preview-src-list="srcList" :z-index="3000"></el-image>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="cancel">Confirm</el-button>
		  </div>
		</el-dialog>
		<!-- 订单轨迹对话窗 -->
		<el-dialog title="订单轨迹信息" :visible.sync="popup" :close-on-click-modal="false" width="880px" :append-to-body="true"
		  class="timeline" :before-close="handleDialogClose">
		  <div class="dialog_orderNo">
		    <label>{{dlvdTobOrderNos}}</label>
		  </div>
			<div class="dialog_extraInfo">
				<label v-show="dlvdMainDeliveryTime != ''">{{'预约送仓时间：' + dlvdMainDeliveryTime}}</label>
				<div class="trackingOpt leave" v-if="leaveTracking != null">
				  <i class="el-icon-ship"></i>
					<span>{{leaveTracking.remarks}}</span>
				  <span>{{leaveTracking.description}}</span>
				</div>
				<div class="trackingOpt arrive" v-if="arriveTracking != null">
				  <i class="el-icon-s-home"></i>
					<span>{{arriveTracking.remarks}}</span>
				  <span>{{arriveTracking.description}}</span>
				</div>
			</div>
		  <el-timeline>
		    <el-timeline-item v-for="(activity, index) in trackingMainData" :key="index" color="green"
		      :timestamp="(activity.initTime)" placement="top" v-show="activity.status==1000?false:true">
		      <el-card v-if="activity.status != 'batchInfo'">
		        <!-- 备注 -->
		        <p style="font-size:16px;line-height: 30px;">
		          <span style="font-weight:700">Remarks：{{activity.remarks}}</span>
		        </p>
		        <!-- 轨迹区域 -->
		        <h4 style="color:#666;font-size:16px;line-height: 30px;">
		          [ {{activity.location}} ]
		          {{activity.description}}
		        </h4>
		      </el-card>
		      <!-- 分批信息 -->
		      <el-card v-if="activity.status == 'batchInfo'">
		        <el-row style="background-color: #f9f9f9; margin-bottom: 15px;border-radius: 10px;padding-top: 20px;" v-for="(subOrderData,subOrderIndex) in subActiveData" :key="subOrderIndex">
		          <div class="dialog_subOrderNo">
		            <label>批次: {{subOrderData[0].esnadTobOrderNo}}</label>
								<div style="display: inline-flex;" v-for="(trackItem,trackIndex) in subOrderData" :key="trackIndex">
								  <label style="color: #5981c9;font-size: 16px;margin-left: 200px;" v-if="trackItem.status == '1000'">{{'预约送仓时间：' + trackItem.description}}</label>
								</div>
		          </div>
		          <!-- 子订单轨迹 -->
		          <el-timeline>
		            <el-timeline-item v-for="(subActivity,subIndex) in subOrderData" :key="subIndex" color="#cbac39"
		            :timestamp="(subActivity.initTime)" placement="top" v-show="subActivity.status==1000?false:true">
		              <p style="font-size:16px;line-height: 30px;">
		                <span style="font-weight:700">{{subActivity.remarks}}</span>
		              </p>
		              <!-- 轨迹区域 -->
		              <h4 style="color:#666;font-size:16px;line-height: 30px;">
		                [ {{subActivity.location}} ]
		                {{subActivity.description}}
		              </h4>
		            </el-timeline-item>
		          </el-timeline>
		        </el-row>
		      </el-card>
		    </el-timeline-item>
		  </el-timeline>
		  <div slot="footer" class="dialog-footer">
				<el-button style="margin-left: 35px;" type="primary" @click="viewPOD(formInline.input)">View POD Photos</el-button>
		    <el-button type="primary" @click="cancel">确认</el-button>
		  </div>
		</el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      formInline: {
        input: ""
      },
      popups: false,
      trackingList: [],
      title: "DLVD Tracking",
      activities: [],
      viewPodPhothos: false,
      dynamicPods: [],
      url: "",
      srcList: [],
      warehouseTime: "",
      onRemarks: "",
      isShow: false,
			rowOrderNo:'',
			dlvdMainDeliveryTime:'',
      isButton: false,
			trackingMainData:[],//展示轨迹数组
			orderType:'',//订单类型 A、空运 S、海运 C、B2C小包
			// 轨迹显示修改
			leaveTracking:null,
			arriveTracking:null,
			
			orderArr:[],//订单号数组
			orderList:[],//订单轨迹数组
			activeOrder:'',//激活的订单tab页
			
			// 分批版本轨迹页面
			popup:false,
			dlvdTobOrderNos:'',
			activiData:[],
			mainActiveData:[],
			subActiveData:[],
    };
  },
  methods: {
    // 订单轨迹查询
    async orderGui() {
			this.orderArr = [];
      if (this.formInline.input.length === 0) {
        return this.$message.warning(
          //请输入订单号
          "Input Order Number"
        );
      }
			this.mainActiveData = [];
			this.subActiveData = [];
			this.trackingMainData = [];
			this.leaveTracking = null;
			this.arriveTracking = null;
      const axios = require("axios");
			// zpy 2022-9-21 新的查询接口(兼容新版海运)
			// 线下
			// http://172.18.0.34:7011/mgr/tracking/
			// 线上
			// https://www.dlvd.com/mgr/tracking/
			// zpy 2024-2-27 线上域名调整
			// https://tracking.fleetan.com
      axios
				.get(
				  "https://tracking.fleetan.com/mgr/tracking/" + this.formInline.input
				)
        .then(res => {
          if (res.data.code === 200)	 {
						this.popup = true;
						this.dlvdTobOrderNos = this.formInline.input;
						this.activiData = res.data.data;
						this.mainActiveData = this.activiData.mainOrderTrackingList;
						this.subActiveData = this.activiData.subOrderTrackingList;
						if (this.mainActiveData.length === 0) {
							//输入的订单号不存在
						  return this.$message.error('获取不到该订单号轨迹，请重新查询');
						}
						// 增加分批轨迹信息 如果有返回子订单轨迹则再主订单轨迹入库完成后面添加对象，来显示子订单分批信息 zpy 2022-7-29
						if(this.subActiveData.length > 0){
						  let index = this.mainActiveData.findIndex((item) => item.status == 'n200');
						  this.mainActiveData.splice(index,0,{
						    initTime:'分批信息',
						    status:'batchInfo',
						  })
						}
						// 显示预计开船或预计到港需要判断轨迹是否已经存在已起航或到港的真实轨迹
						let trueLeave = this.mainActiveData.filter(item => item.status == '300');
						let trueArrive = this.mainActiveData.filter(item => item.status == '320');
						// 父订单是否有预约时间
						this.mainActiveData.forEach(mainItem => {
						  if(mainItem.status == '1000'){
						    this.dlvdMainDeliveryTime = mainItem.description
						  }
							// 海运轨迹
							if(mainItem.status == '230'){//预计开船
								if(trueLeave.length == 0){// 已有起航轨迹就不需要显示预计开船时间轨迹
								  this.leaveTracking = mainItem;
								}
							} else if(mainItem.status == '310'){//预计到港
								if(trueArrive.length == 0){// 已有到港轨迹就不需要显示预计到港时间轨迹
								  this.arriveTracking = mainItem;
								}
							}else{
								this.trackingMainData.push(mainItem);
							}
						})
						
						
						// if(res.data.data.length === 0){
						// 	this.$message.error("The order number entered does not exist");
						// }else{
						// 	this.activities = res.data.data;
						// 	this.popups = true;
						// 	this.rowOrderNo =this.formInline.input;
						// 	this.activities.forEach(item => {
						// 	  if (item.status == 1000 && item.id) {
						// 	    this.warehouseTime = item.description;
						// 	    this.onRemarks = item.remarks;
						// 	    this.isShow = true;
						// 	  }
						// 	  if (item.id) {
						// 	    this.isButton = true;
						// 	  } else {
						// 	    this.isButton = false;
						// 	  }
							
						// 	  let indexLocation = item.location.indexOf(":");
						// 	  item.location = item.location.substring(
						// 	    indexLocation + 1,
						// 	    item.location.length
						// 	  );
							
						// 	  let indexRemarks = item.remarks.indexOf(":");
						// 	  item.remarks = item.remarks.substring(
						// 	    indexRemarks + 1,
						// 	    item.remarks.length
						// 	  );
						// 	  // console.log(item);
						// 	});
						// }
          }
        });
    },
    // 弹出层x号关闭
    handleDialogClose() {
      // alert(1);
      this.popups = false;
			this.popup = false;
      this.isShow = false;
      this.warehouseTime = "";
      this.onRemarks = "";
			this.title = 'DLVD Tracking';
			this.dlvdMainDeliveryTime = '';
    },
    viewPOD(orderNo) {
      // const res = await this.$http.get(
      //   "https:/vip.dlvd.com/api/mgr/toborder/orderinfo/pod/" +
      //     this.formInline.input
      // );
      const axios = require("axios");
      axios
        .get(
          "https://vip.dlvd.com/api/mgr/toborder/orderinfo/pod/" +
            orderNo
        )
        .then(res => {
          if (res.data.code == 200) {
            // console.log(res.data);
            this.dynamicPods = res.data.data;
            this.viewPodPhothos = true;
            // console.log(this.dynamicPods);
            this.dynamicPods.forEach(item => {
              // console.log(item);
              this.srcList.push(item.link);
            });
          }else if(res.data.code == 500){
						this.$message.error(
						  res.data.msg
						);
					}
        })
        .catch(err => {
					this.$message.error(
					  err.msg
					);
          // console.log(res);
        });
    },
    // 取消按钮
    cancel() {
      this.popups = false;
			this.popup = false;
      this.viewPodPhothos = false;
			this.dlvdMainDeliveryTime = '';
    },
    // 格式化日期
    dateFormat: function(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        " " +
        "Time Zone：Beijing"
      );
    }
  },
	created() {
		document.title = 'Fleetan Tracking'
	}
};
</script>
<style>
.track{
	width: 100%;
	height: 100%;
	position: relative;
}
.titleRow{
	width: 100%;
	height: 80px;
}
.el-input__inner {
  height: 48px !important;
}
.el-image__error,
.el-image__inner,
.el-image__placeholder {
  width: 94% !important;
}
.dialog-title{
	font-size: 20px;
}
.dialog-title span{
	margin-left: 10px;
}
.dialog_orderNo{
	width: 100%;
	height: 50px;
	line-height: 40px;
}
.dialog_orderNo label{
	margin-left: 40px;
	font-weight: bold;
	border-radius: 8px;
	font-size: 20px;
	padding: 5px 15px;
	border: 1px solid #EBEEF5;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
}
.el-dialog__body{
	padding: 15px 20px !important;
}
.dialog_warehouseTime{
	width: 100%;
	color: rgb(245, 40, 40);
	height: 60px;
	line-height: 25px;
	font-size: 20px;
	padding-left: 35px;
}
.dialog_orderNo {
    width: 100%;
    height: 50px;
    line-height: 26px;
  }

  .dialog_orderNo label {
		float: left;
    margin-left: 40px;
    font-weight: bold;
    border-radius: 8px;
    font-size: 20px;
    padding: 5px 15px;
    border: 1px solid #EBEEF5;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  }
	.dialog_orderNo label:nth-child(2){
	  float: right;
	  color: #5981c9;
	  font-size: 18px;
	}
  /* 子订单号 */
  .dialog_subOrderNo{
    width: 100%;
    height: 40px;
    line-height: 30px;
  }
  .dialog_subOrderNo label {
    margin-left: 20px;
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 10px;
  }
	.dialog_extraInfo{
		width: 100%;
		padding: 15px 25px;
		margin-bottom: 5px;
	}
	.dialog_extraInfo label{
		margin: 10px 15px;
		font-weight: bold;
		border-radius: 8px;
		color: #5981c9;
		font-size: 18px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.trackingOpt{
	  display: inline-block;
		width: 500px;
	  margin: 4px 15px;
	  padding: 8px 10px;
	  font-size: 14px;
	  color: #ededed;
	  border-radius: 6px;
	  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.trackingOpt i{
		font-size: 26px;
		display: block;
		float: left;
		width: 40px;
		height: 50px;
		line-height: 50px;
	}
	.trackingOpt span{
		width: 440px;
		line-height: 24px;
		display: block;
		float: left;
	}
	.leave{
	  background-color: #01AAED;
	  border: 1px solid #01AAED;
	}
	.arrive{
	  background-color: #1e8f86;
	  border: 1px solid #1e8f86;
	}
</style>